<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container class="fill-height login" fluid>
        <v-row align="center">
          <v-col align-self="center" class="d-flex justify-center">
            <v-card elevation="10" class="mx-auto">
              <v-img height="300" :src="immbg" class="white--text align-end">
                <v-card-title>
                  <v-icon color="white">mdi-cellphone-iphone</v-icon>ยืนยันดัวตน
                  <v-spacer></v-spacer>
                  <span class="caption"> TPCC (version: {{ version }})</span>
                </v-card-title>
              </v-img>
              <v-container>
                <v-alert type="error" v-if="errorMessage != ''">
                  {{ errorMessage }}
                </v-alert>
                <v-alert type="info"> กรอก OTP ที่ได้รับจากเบอร์ {{ phone }} </v-alert>
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="login">
                  <v-text-field
                    v-model="otp"
                    name="otp"
                    :rules="usernameRules"
                    maxlength="6"
                    hint="กรอก OTP ที่ได้รับจากโทรศัพท์"
                    label="OTP"
                    required
                  >
                  </v-text-field>
                </v-form>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="indigo darken-4" dark @click="login" :loading="loading" x-large>
                  ยืนยัน
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import immbg from '@/assets/card_img1.png'
export default {
  data() {
    return {
      valid: false,
      immbg,
      id: '',
      otp: '',
      phone: '',
      usernameRules: [v => /\d{6,6}/.test(v) || 'กรอก otp ให้ถูกต้อง'],
      password: '',
      passwordRules: [v => !!v || 'กรอก Password'],
      loading: false,
      errorMessage: '',
      version: '',
    }
  },
  methods: {
    login() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      const user = {
        otp: this.otp,
        id: this.id,
      }
      this.$store
        .dispatch('Auth/verifyOtp', user)
        .then(() => {
          this.$router.push({ name: 'Dashboard' })
        })
        .catch(err => {
          // console.log(err)
          this.errorMessage = err.error == 'invalid_grant' ? 'ไม่พบผู้ใช้งานนี้ในระบบ' : err.error
          if (err.response) {
            this.errorMessage = err.response.data.error
          }

          this.$refs.form.reset()
        })
        .finally(() => (this.loading = false))
    },
  },
  computed: {},
  created() {
    this.version = process.env.VUE_APP_VERSION
    this.id = this.$store.state.Auth.tokenId
    this.phone = this.$store.state.Auth.tokenMobile
  },
}
</script>

<style scope>
.login {
  background-image: url('../../assets/login-bg1.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
